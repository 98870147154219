import {
  StyleParamType,
  wixColorParam,
  wixFontParam,
  createStylesParams,
  IWixStyleFont,
} from '@wix/tpa-settings';
import type { CustomCssVarsFn } from '@wix/yoshi-flow-editor';

export const fontValueFix = (fontFn, textPresets): IWixStyleFont => {
  const { value: _, ...font } = fontFn({ textPresets });

  return {
    ...font,
  } as IWixStyleFont;
};

const stylesParams = createStylesParams<{
  sectionTitleBookButtonVisibility: StyleParamType.Boolean;
  sectionSchedulingBookButtonVisibility: StyleParamType.Boolean;
  sectionContactBookButtonVisibility: StyleParamType.Boolean;
  sectionDescriptionBookButtonVisibility: StyleParamType.Boolean;
  sectionDetailsBookButtonVisibility: StyleParamType.Boolean;
  sectionGalleryBookButtonVisibility: StyleParamType.Boolean;
  sectionPluginBookButtonVisibility: StyleParamType.Boolean;
  sectionPolicyBookButtonVisibility: StyleParamType.Boolean;
  headerVisibility: StyleParamType.Boolean;
  headerTitleVisibility: StyleParamType.Boolean;
  headerImageVisibility: StyleParamType.Boolean;
  headerBookButtonVisibility: StyleParamType.Boolean;
  sidebarVisibility: StyleParamType.Boolean;
  onlineBadgeVisibility: StyleParamType.Boolean;
  serviceTitleVisibility: StyleParamType.Boolean;
  serviceTaglineVisibility: StyleParamType.Boolean;
  detailsDurationVisibility: StyleParamType.Boolean;
  detailsPriceVisibility: StyleParamType.Boolean;
  detailsLocationVisibility: StyleParamType.Boolean;
  businessEmailVisibility: StyleParamType.Boolean;
  businessPhoneNumberVisibility: StyleParamType.Boolean;
  businessAddressVisibility: StyleParamType.Boolean;
  locationNameVisibility: StyleParamType.Boolean;
  scheduleDurationVisibility: StyleParamType.Boolean;
  scheduleStaffVisibility: StyleParamType.Boolean;
  headerHeight: StyleParamType.Number;
  headerSidePadding: StyleParamType.Number;
  headerImageOpacity: StyleParamType.Number;
  headerImageBackgroundColor: StyleParamType.Color;
  headerBackgroundColor: StyleParamType.Color;
  headerTitleFont: StyleParamType.Font;
  headerTitleColor: StyleParamType.Color;
  bodyBackgroundColor: StyleParamType.Color;
  bodyServiceTitleFont: StyleParamType.Font;
  bodyServiceTitleColor: StyleParamType.Color;
  bodyServiceTaglineFont: StyleParamType.Font;
  bodyServiceTaglineColor: StyleParamType.Color;
  bodySectionTitleFont: StyleParamType.Font;
  bodySectionTitleColor: StyleParamType.Color;
  bodyTextFont: StyleParamType.Font;
  bodyTextColor: StyleParamType.Color;
  courseAvailabilityTextFont: StyleParamType.Font;
  courseAvailabilityTextColor: StyleParamType.Color;
  bodyDividerWidth: StyleParamType.Number;
  bodyDividerColor: StyleParamType.Color;
  sidebarBackgroundColor: StyleParamType.Color;
  sidebarTitleColor: StyleParamType.Color;
  sidebarTitleFont: StyleParamType.Font;
  sidebarTextColor: StyleParamType.Color;
  sidebarTextFont: StyleParamType.Font;
  sidebarServiceTitleColor: StyleParamType.Color;
  sidebarServiceTitleFont: StyleParamType.Font;
  sidebarServiceTaglineColor: StyleParamType.Color;
  sidebarServiceTaglineFont: StyleParamType.Font;
  sidebarButtonTextColor: StyleParamType.Color;
  sidebarButtonTextFont: StyleParamType.Font;
  sidebarButtonBackgroundColor: StyleParamType.Color;
  sidebarButtonBorderColor: StyleParamType.Color;
  sidebarButtonBorderWidth: StyleParamType.Number;
  sidebarButtonCornerRadius: StyleParamType.Number;
  sidebarButtonHoverTextColor: StyleParamType.Color;
  sidebarButtonHoverBackgroundColor: StyleParamType.Color;
  sidebarButtonHoverBorderColor: StyleParamType.Color;
  sidebarWidth: StyleParamType.Number;
  sidebarSidePadding: StyleParamType.Number;
  sidebarSideSpacing: StyleParamType.Number;
  sidebarSpaceFromTheTop: StyleParamType.Number;
  headerButtonTextColor: StyleParamType.Color;
  headerButtonTextFont: StyleParamType.Font;
  headerButtonBackgroundColor: StyleParamType.Color;
  headerButtonBorderColor: StyleParamType.Color;
  headerButtonBorderWidth: StyleParamType.Number;
  headerButtonCornerRadius: StyleParamType.Number;
  headerButtonHoverTextColor: StyleParamType.Color;
  headerButtonHoverBackgroundColor: StyleParamType.Color;
  headerButtonHoverBorderColor: StyleParamType.Color;
  bodyButtonTextColor: StyleParamType.Color;
  bodyButtonTextFont: StyleParamType.Font;
  bodyButtonBackgroundColor: StyleParamType.Color;
  bodyButtonBorderColor: StyleParamType.Color;
  bodyButtonBorderWidth: StyleParamType.Number;
  bodyButtonCornerRadius: StyleParamType.Number;
  bodyButtonHoverTextColor: StyleParamType.Color;
  bodyButtonHoverBackgroundColor: StyleParamType.Color;
  bodyButtonHoverBorderColor: StyleParamType.Color;
  bodySidePadding: StyleParamType.Number;
  bodySpaceBetweenSections: StyleParamType.Number;
  firstSectionSpaceFromTheTop: StyleParamType.Number;
  backgroundColor: StyleParamType.Color;
  detailsInnerPadding: StyleParamType.Number;
  detailsBoxFont: StyleParamType.Font;
  detailsBoxTextColor: StyleParamType.Color;
  detailsBoxColor: StyleParamType.Color;
  detailsBoxBorderColor: StyleParamType.Color;
  detailsBoxBorderWidth: StyleParamType.Number;
  detailsBoxBorderRadius: StyleParamType.Number;
  scheduleBoxPadding: StyleParamType.Number;
  scheduleBackgroundColor: StyleParamType.Color;
  scheduleBorderColor: StyleParamType.Color;
  scheduleBorderWidth: StyleParamType.Number;
  scheduleBorderRadius: StyleParamType.Number;
  scheduleDividerColor: StyleParamType.Color;
  scheduleDividerWidth: StyleParamType.Number;
  scheduleDatesFont: StyleParamType.Font;
  scheduleDatesColor: StyleParamType.Color;
  scheduleHoursFont: StyleParamType.Font;
  scheduleHoursColor: StyleParamType.Color;
  scheduleDetailsFont: StyleParamType.Font;
  scheduleDetailsColor: StyleParamType.Color;
  scheduleLinkFont: StyleParamType.Font;
  scheduleLinkColor: StyleParamType.Color;
  galleryMasonryItemBorderColor: StyleParamType.Color;
  galleryGridItemBorderColor: StyleParamType.Color;
  gallerySliderItemBorderColor: StyleParamType.Color;
  gallerySliderArrowColor: StyleParamType.Color;
  mobileServiceTitleFontSize: StyleParamType.Number;
  mobileServiceTaglineFontSize: StyleParamType.Number;
  mobileSectionTitleFontSize: StyleParamType.Number;
  mobileTextFontSize: StyleParamType.Number;
  mobileButtonTextSize: StyleParamType.Number;
  mobileHeaderTitleFontSize: StyleParamType.Number;
  mobileHeaderButtonTextSize: StyleParamType.Number;
  mobileDetailsBoxFontSize: StyleParamType.Number;
  mobileScheduleDatesFontSize: StyleParamType.Number;
  mobileScheduleHoursFontSize: StyleParamType.Number;
  mobileScheduleDetailsFontSize: StyleParamType.Number;
  mobileScheduleLinkFontSize: StyleParamType.Number;
}>({
  sectionTitleBookButtonVisibility: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => false,
  },
  sectionSchedulingBookButtonVisibility: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  sectionContactBookButtonVisibility: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => false,
  },
  sectionDescriptionBookButtonVisibility: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  sectionDetailsBookButtonVisibility: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  sectionGalleryBookButtonVisibility: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => false,
  },
  sectionPluginBookButtonVisibility: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => false,
  },
  sectionPolicyBookButtonVisibility: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => false,
  },
  headerVisibility: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  headerTitleVisibility: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => false,
  },
  headerImageVisibility: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  headerBookButtonVisibility: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => false,
  },
  sidebarVisibility: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => false,
  },
  onlineBadgeVisibility: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  serviceTitleVisibility: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  serviceTaglineVisibility: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  detailsDurationVisibility: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  detailsPriceVisibility: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  detailsLocationVisibility: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  businessEmailVisibility: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  businessPhoneNumberVisibility: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  businessAddressVisibility: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  locationNameVisibility: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => false,
  },
  scheduleDurationVisibility: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  scheduleStaffVisibility: {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  headerHeight: {
    type: StyleParamType.Number,
    getDefaultValue: ({ isMobile }) => (isMobile ? 220 : 360),
    inheritDesktop: false,
  },
  headerSidePadding: {
    type: StyleParamType.Number,
    getDefaultValue: () => 20,
    inheritDesktop: false,
  },
  headerImageOpacity: {
    type: StyleParamType.Number,
    getDefaultValue: () => 100,
  },
  headerImageBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  bodyBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  headerBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  headerTitleFont: {
    type: StyleParamType.Font,
    getDefaultValue: ({ textPresets, isMobile }) => {
      return fontValueFix(
        wixFontParam('Page-title', {
          size: isMobile ? 32 : 40,
        }),
        textPresets,
      );
    },
  },
  headerTitleColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  bodySectionTitleFont: {
    type: StyleParamType.Font,
    getDefaultValue: ({ textPresets }) => {
      return fontValueFix(
        wixFontParam('Page-title', {
          size: 20,
        }),
        textPresets,
      );
    },
  },
  bodySectionTitleColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  bodyTextFont: {
    type: StyleParamType.Font,
    getDefaultValue: ({ textPresets, isMobile }) => {
      return fontValueFix(
        wixFontParam('Body-M', {
          size: isMobile ? 14 : 16,
        }),
        textPresets,
      );
    },
  },
  bodyTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  courseAvailabilityTextFont: {
    type: StyleParamType.Font,
    getDefaultValue: ({ textPresets, isMobile }) => {
      return fontValueFix(
        wixFontParam('Body-M', {
          size: isMobile ? 14 : 16,
        }),
        textPresets,
      );
    },
  },
  courseAvailabilityTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  bodySidePadding: {
    type: StyleParamType.Number,
    getDefaultValue: () => 20,
    inheritDesktop: false,
  },
  firstSectionSpaceFromTheTop: {
    type: StyleParamType.Number,
    getDefaultValue: () => 48,
    inheritDesktop: false,
  },
  bodySpaceBetweenSections: {
    type: StyleParamType.Number,
    getDefaultValue: () => 32,
    inheritDesktop: false,
  },
  sidebarBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  sidebarTitleColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  sidebarTitleFont: {
    type: StyleParamType.Font,
    getDefaultValue: ({ textPresets }) => {
      return fontValueFix(
        wixFontParam('Page-title', {
          size: 20,
        }),
        textPresets,
      );
    },
  },
  sidebarTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  sidebarTextFont: {
    type: StyleParamType.Font,
    getDefaultValue: ({ textPresets, isMobile }) => {
      return fontValueFix(
        wixFontParam('Body-M', {
          size: isMobile ? 14 : 16,
        }),
        textPresets,
      );
    },
  },
  sidebarServiceTitleColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  sidebarServiceTitleFont: {
    type: StyleParamType.Font,
    getDefaultValue: ({ textPresets, isMobile }) => {
      return fontValueFix(
        wixFontParam('Page-title', {
          size: isMobile ? 32 : 40,
        }),
        textPresets,
      );
    },
  },
  sidebarServiceTaglineColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  sidebarServiceTaglineFont: {
    type: StyleParamType.Font,
    getDefaultValue: ({ textPresets, isMobile }) => {
      return fontValueFix(
        wixFontParam('Body-M', {
          size: isMobile ? 14 : 16,
        }),
        textPresets,
      );
    },
  },
  sidebarWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 30,
  },
  sidebarSidePadding: {
    type: StyleParamType.Number,
    getDefaultValue: () => 20,
    inheritDesktop: false,
  },
  sidebarSideSpacing: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
    inheritDesktop: false,
  },
  sidebarSpaceFromTheTop: {
    type: StyleParamType.Number,
    getDefaultValue: () => 48,
    inheritDesktop: false,
  },
  backgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  detailsInnerPadding: {
    type: StyleParamType.Number,
    getDefaultValue: () => 16,
    inheritDesktop: false,
  },
  detailsBoxFont: {
    type: StyleParamType.Font,
    getDefaultValue: ({ textPresets, isMobile }) => {
      return fontValueFix(
        wixFontParam('Body-M', {
          size: isMobile ? 14 : 16,
        }),
        textPresets,
      );
    },
  },
  detailsBoxTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  detailsBoxColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1', 0),
  },
  detailsBoxBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.2),
  },
  detailsBoxBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  sidebarButtonTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  sidebarButtonTextFont: {
    type: StyleParamType.Font,
    getDefaultValue: ({ textPresets, isMobile }) => {
      return fontValueFix(
        wixFontParam('Body-M', {
          size: isMobile ? 14 : 16,
        }),
        textPresets,
      );
    },
  },
  sidebarButtonBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  sidebarButtonBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  sidebarButtonBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  sidebarButtonCornerRadius: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  sidebarButtonHoverTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  sidebarButtonHoverBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8', 0.7),
  },
  sidebarButtonHoverBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8', 0.7),
  },
  bodyButtonTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  bodyButtonTextFont: {
    type: StyleParamType.Font,
    getDefaultValue: ({ textPresets, isMobile }) => {
      return fontValueFix(
        wixFontParam('Body-M', {
          size: isMobile ? 14 : 16,
        }),
        textPresets,
      );
    },
  },
  bodyButtonBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8', 1),
  },
  bodyButtonBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8', 1),
  },
  bodyButtonBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  bodyButtonCornerRadius: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  bodyButtonHoverTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  bodyButtonHoverBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8', 0.7),
  },
  bodyButtonHoverBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8', 0.7),
  },
  headerButtonTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  headerButtonTextFont: {
    type: StyleParamType.Font,
    getDefaultValue: ({ textPresets, isMobile }) => {
      return fontValueFix(
        wixFontParam('Body-M', {
          size: isMobile ? 14 : 16,
        }),
        textPresets,
      );
    },
  },
  headerButtonBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  headerButtonBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  headerButtonBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  headerButtonCornerRadius: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  headerButtonHoverTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  headerButtonHoverBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1', 0.7),
  },
  headerButtonHoverBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1', 0.7),
  },
  bodyDividerWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  detailsBoxBorderRadius: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  bodyDividerColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.2),
  },
  bodyServiceTitleFont: {
    type: StyleParamType.Font,
    getDefaultValue: ({ textPresets, isMobile }) => {
      return fontValueFix(
        wixFontParam('Page-title', {
          size: isMobile ? 32 : 40,
        }),
        textPresets,
      );
    },
  },
  bodyServiceTitleColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  bodyServiceTaglineFont: {
    type: StyleParamType.Font,
    getDefaultValue: ({ textPresets, isMobile }) => {
      return fontValueFix(
        wixFontParam('Body-M', {
          size: isMobile ? 14 : 16,
        }),
        textPresets,
      );
    },
  },
  bodyServiceTaglineColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  scheduleBoxPadding: {
    type: StyleParamType.Number,
    getDefaultValue: ({ isMobile }) => (isMobile ? 20 : 28),
    inheritDesktop: false,
  },
  scheduleBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1', 0),
  },
  scheduleBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.2),
  },
  scheduleBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  scheduleBorderRadius: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  scheduleDividerColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.2),
  },
  scheduleDividerWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  scheduleDatesFont: {
    type: StyleParamType.Font,
    getDefaultValue: ({ textPresets, isMobile }) => {
      return fontValueFix(
        wixFontParam('Body-M', {
          size: isMobile ? 14 : 16,
        }),
        textPresets,
      );
    },
  },
  scheduleDatesColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  scheduleHoursFont: {
    type: StyleParamType.Font,
    getDefaultValue: ({ textPresets, isMobile }) => {
      return fontValueFix(
        wixFontParam('Body-M', {
          size: isMobile ? 14 : 16,
        }),
        textPresets,
      );
    },
  },
  scheduleHoursColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  scheduleDetailsFont: {
    type: StyleParamType.Font,
    getDefaultValue: ({ textPresets }) => {
      return fontValueFix(
        wixFontParam('Body-M', {
          size: 14,
        }),
        textPresets,
      );
    },
  },
  scheduleDetailsColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-4'),
  },
  scheduleLinkFont: {
    type: StyleParamType.Font,
    getDefaultValue: ({ textPresets, isMobile }) => {
      return fontValueFix(
        wixFontParam('Body-M', {
          size: isMobile ? 14 : 16,
        }),
        textPresets,
      );
    },
  },
  scheduleLinkColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  galleryMasonryItemBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.2),
  },
  galleryGridItemBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.2),
  },
  gallerySliderItemBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.2),
  },
  gallerySliderArrowColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  mobileServiceTitleFontSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 32,
  },
  mobileServiceTaglineFontSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 14,
  },
  mobileSectionTitleFontSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 20,
  },
  mobileTextFontSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 14,
  },
  mobileButtonTextSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 14,
  },
  mobileHeaderTitleFontSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 32,
  },
  mobileHeaderButtonTextSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 14,
  },
  mobileDetailsBoxFontSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 14,
  },
  mobileScheduleDatesFontSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 14,
  },
  mobileScheduleHoursFontSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 14,
  },
  mobileScheduleDetailsFontSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 14,
  },
  mobileScheduleLinkFontSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 14,
  },
});

export default stylesParams;

// noinspection JSUnusedGlobalSymbols
export const customCssVars: CustomCssVarsFn = ({ styleParams }) => {
  const displayStyle = (
    booleanStyleParam: keyof typeof stylesParams,
    visibleDisplay = 'block',
  ) => (styleParams.booleans[booleanStyleParam] ? visibleDisplay : 'none');

  return {
    headerDisplay: displayStyle('headerVisibility', 'flex'),
    headerTitleDisplay: displayStyle('headerTitleVisibility'),
    headerImageDisplay: displayStyle('headerImageVisibility'),
    headerBookButtonDisplay: displayStyle('headerBookButtonVisibility'),
    sidebarDisplay: displayStyle('sidebarVisibility'),
    onlineBadgeDisplay: displayStyle('onlineBadgeVisibility'),
    serviceTitleDisplay: displayStyle('serviceTitleVisibility'),
    serviceTaglineDisplay: displayStyle('serviceTaglineVisibility'),
    detailsDurationDisplay: displayStyle('detailsDurationVisibility'),
    detailsPriceDisplay: displayStyle('detailsPriceVisibility'),
    detailsLocationDisplay: displayStyle('detailsLocationVisibility'),
    businessEmailDisplay: displayStyle('businessEmailVisibility'),
    businessPhoneNumberDisplay: displayStyle('businessPhoneNumberVisibility'),
    businessAddressDisplay: displayStyle('businessAddressVisibility'),
    locationNameDisplay: displayStyle('locationNameVisibility'),
    scheduleDurationDisplay: displayStyle('scheduleDurationVisibility'),
    scheduleStaffDisplay: displayStyle('scheduleStaffVisibility'),
    sectionTitleBookButtonDisplay: displayStyle(
      'sectionTitleBookButtonVisibility',
    ),
    sectionSchedulingBookButtonDisplay: displayStyle(
      'sectionSchedulingBookButtonVisibility',
    ),
    sectionContactBookButtonDisplay: displayStyle(
      'sectionContactBookButtonVisibility',
    ),
    sectionDescriptionBookButtonDisplay: displayStyle(
      'sectionDescriptionBookButtonVisibility',
    ),
    sectionDetailsBookButtonDisplay: displayStyle(
      'sectionDetailsBookButtonVisibility',
    ),
    sectionGalleryBookButtonDisplay: displayStyle(
      'sectionGalleryBookButtonVisibility',
    ),
    sectionPluginBookButtonDisplay: displayStyle(
      'sectionPluginBookButtonVisibility',
    ),
    sectionPolicyBookButtonDisplay: displayStyle(
      'sectionPolicyBookButtonVisibility',
    ),
  };
};
